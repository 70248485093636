import React, {useState, useEffect, memo} from "react";
import {Line, Bar} from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import {motion, AnimatePresence} from "framer-motion";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {FiSettings, FiX, FiArrowLeft, FiBarChart2} from "react-icons/fi";
import {Switch} from "@headlessui/react";
import dayjs from 'dayjs';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const MemoizedGaugeChart = memo(({threadCount}) => (
    <div className="w-full max-w-xs">
        <GaugeChart
            id="gauge-chart-thread"
            nrOfLevels={20}
            colors={["#22C55E", "#FACC15", "#EF4444"]}
            arcWidth={0.2}
            percent={threadCount / 1000}
            textColor="#D1D5DB"
            needleColor="#D1D5DB"
            animateDuration={900}
            style={{width: "100%"}}
        />
    </div>
));

const ProjectCMC = () => {
    const navigate = useNavigate();

    const [isModalOpen, setModalOpen] = useState(false);

    const serverInstances = [
        {id: '4', name: 'c3-4', ram: '4 GB', vcpus: 2, bandwidth: '250 Mbps', cost: 0.0453},
        {id: '8', name: 'c3-8', ram: '8 GB', vcpus: 4, bandwidth: '500 Mbps', cost: 0.0907},
        {id: '16', name: 'c3-16', ram: '16 GB', vcpus: 8, bandwidth: '1 Gbps', cost: 0.1813},
        {id: '32', name: 'c3-32', ram: '32 GB', vcpus: 16, bandwidth: '2 Gbps', cost: 0.3627},
    ];

    const [serverCreated, setServerCreated] = useState(false);

    const handleCreateServer = async () => {
        if (serverStatus === 'ERROR' || serverStatus === 'ACTIVE' || serverStatus === 'BUILD') {
            toast.warn('A server is already active or being created.');
            return;
        }

        const token = localStorage.getItem("token");

        setServerStatus('BUILD');
        toast.info('Creating server instance...');

        try {
            // Find the selected instance
            const instance = serverInstances.find(inst => inst.id === selectedInstance);

            if (!instance) {
                toast.error('Selected instance not found!');
                setServerStatus('idle');
                return;
            }

            // Send POST request to /server with instanceType as body
            await axios.post('/api/projects/cmc/server', instance.name, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                transformRequest: [
                    (data) => {
                        // Ensure it's sent as raw JSON without quotes
                        return JSON.stringify(data);
                    },
                ],
            });

            // Handle successful response
            toast.success('Server instance created successfully!');
            setServerStatus('BUILD');
            setServerCreated(true);
        } catch (error) {
            // Handle errors
            toast.error('Failed to create server instance (balance issues?)');
            console.error('Server creation error:', error);
        }
    };

    // Handler to destroy server
    const handleDeleteServer = async () => {
        if (!(serverStatus === 'READY' || serverStatus === 'ERROR')) {
            toast.warn('No active server to destroy.');
            return;
        }

        const token = localStorage.getItem('token');

        try {
            // Send POST request to /server with instanceType as body
            await axios.delete('/api/projects/cmc/server', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                transformRequest: [
                    (data) => {
                        // Ensure it's sent as raw JSON without quotes
                        return JSON.stringify(data);
                    },
                ],
            });

            toast.success('Server instance deleted successfully!');
            setServerCreated(false);
        } catch (error) {
            toast.error('Failed to delete server instance.');
            console.error('Server deletion error:', error);
        }
    };

    // Handler to destroy server
    const handleRestartServer = async () => {
        if (serverStatus !== 'READY') {
            toast.warn('No active server to restart.');
            return;
        }

        let token = localStorage.getItem('token');
        toast.info('Restarting server instance...');

        try {
            const response = await axios.post('/api/projects/cmc/restart', null, {
                headers: {
                    Authorization: `Bearer ${token}`, // Replace with your actual token
                },
            });

            if (response.status === 200) {
                toast.success('Server instance restarted successfully!');
            } else {
                toast.error('Server restart failed. Please try again.');
            }
        } catch (error) {
            console.error('Error restarting server:', error);
            toast.error('Failed to restart server. Please check your connection or server status.');
        }
    };

    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted
        let token = localStorage.getItem("token");

        const fetchServerStatus = async () => {
            try {
                const response = await axios.get('/api/projects/cmc/server', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });

                if (isMounted) {
                    if (response.status === 200) {
                        const server = response.data;

                        const matchingInstance = serverInstances.find(
                            (instance) => instance.name === server.instanceType
                        );

                        if (matchingInstance) {
                            setSelectedInstance(matchingInstance.id); // Set selectedInstance based on the match
                        //    toast.success(`Server instance selected: ${matchingInstance.name}`);
                        } else {
                            toast.warn('No matching server instance found.');
                        }

                        // Check the server's status field
                        if (server.status === 'ACTIVE') {
                            setServerStatus("ACTIVE");
                        } else if (server.status === 'BUILD') {
                            setServerStatus("BUILD");
                        } else if (server.status === 'READY') {
                            setServerStatus("READY");
                        } else if (server.status === 'ERROR') {
                            setServerStatus("ERROR");
                        } else {
                            toast.warn(`Unknown Server status: ${server.status}`);
                        }

                        setServerCreated(true);
                    } else {
                        setServerCreated(false);
                    }
                }
            } catch (error) {
                if (isMounted) {
                    if (error.response) {
                        if (error.response.status === 400) {
                            setServerCreated(false);
                        } else {
                            toast.error(`Error: ${error.response.status}`);
                        }
                    } else {
                        toast.error('Unable to connect to the server.');
                    }
                    console.error('Error fetching server:', error);
                }
            }
        };

        fetchServerStatus();
        const interval = setInterval(() => {
            fetchServerStatus();
        }, 7000); // Run every 7 seconds

        // Cleanup function to prevent state updates after unmount
        return () => {
            isMounted = false;
            clearInterval(interval); // Clear the interval to avoid memory leaks
        };
    }, []); // Empty dependency array ensures this runs only once on component mount



    const handleInstanceChange = (e) => {
        setSelectedInstance(e.target.value);
    };

    // Local states for proxy inputs
    const [proxyUsernameLocal, setProxyUsernameLocal] = useState('');
    const [proxyPasswordLocal, setProxyPasswordLocal] = useState('');

    // State for proxy testing
    const [isTestingProxy, setIsTestingProxy] = useState(false);

    const handleProxyCheckAndSave = async () => {
        const token = localStorage.getItem("token");
        setIsTestingProxy(true);
        try {
            // Use toast.promise to show pending, success, and error states
            const sendObject = {
                proxyUsernameCustom: proxyUsernameLocal,
                proxyPasswordCustom: proxyPasswordLocal
            };

            await toast.promise(
                axios.put('/api/projects/cmc/settings/proxies', sendObject, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }),
                {
                    pending: 'Checking proxy...',
                    success: 'Proxy test successful! Credentials saved.',
                    error: 'Unable to connect to the proxy with these credentials.',
                }
            );


            // If we get here, the test succeeded
        } catch (error) {
            // The error state is handled by toast.promise, no need for manual UI updates here
        } finally {
            setIsTestingProxy(false);
        }
    };


    const [selectedInstance, setSelectedInstance] = useState(serverInstances[0].id);
    const [serverStatus, setServerStatus] = useState('inactive'); // 'inactive', 'creating', 'active', 'destroying'
    const settingDescriptions = {
        url: "Enter in the URL as it displays from CMC for your coin",
        ghostInteractions: "How many ghost interactions should there be per day? Format: min amt - max amt",
        accountsPoolPerDay: "How many accounts out of the pool should we utilize per day? Format: [min amt - max amt] OR the word max",
        interactionLength: "Around how many seconds should an interaction be with a coin? Format: min amt - max amt",
        scheduleAccountPerDay: "How many times should an account be scheduled in for an interaction session per day? Format: min amt - max amt",
        delayBetweenCoins: "How long should an account take between viewing one coin and another? Format: min amt - max amt",
        ghostRetainPercent: "What percent of ghost profiles should be retained after usage? Format: [decimal <=1] - [decimal <= 1]",
        topHundredCoinAssignment: "How many coins from the top 100 should be assigned to account profiles? Format: min amt - max amt",
        coinsViewedPerSess: "How many coins should be viewed per account session? Format: min amt - max amt",
        chanceOfYourCoin: "Chance of your coin being viewed in a session? Format: decimal <= 1",
        peakHours: "Hours the application should be most active in? [60% of application flow scheduling] Format: comma seperated list",
        inactiveHours: "Hours the application should be least active in? [10% of application flow scheduling] Format: comma seperated list",
        maintainWatchlist: "How many coins should an account maintain in its watchlist? Format: min amt - max amt",
        ourWatchlistChance: "What's the chance of your coin being one of the coins an account adopts in its watchlist? Format: decimal <= 1",
        flowForRegistered: "Should newly registered accounts wait for the daily smart scheduler to be utilized? Format: boolean value [true/false]",
        registrationMode: "Options: onsite/binance - onsite will register using email/pass on the cmc site, binance will register on binance and then use oAuth to create a cmc account"

    };

    const [moduleStatus, setModuleStatus] = useState({
        Registration: false,
        "Interaction Handler": false,
    });

    const [metricsModalOpen, setMetricsModalOpen] = useState(false);
    const [threadCount, setThreadCount] = useState(0);
    const [accountNumber, setAccountNumber] = useState(0);
    const [showLogsModal, setShowLogsModal] = useState(false);

    const generateLabels = () => {
        const daysOfWeek = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat"];
        const today = new Date();
        const labels = [];

        const todayIndex = today.getDay();

        for (let i = 6; i >= 0; i--) {
            const dayIndex = (todayIndex - i + 7) % 7;
            labels.push(daysOfWeek[dayIndex]);
        }

        return labels;
    };


    const [newSettings, setNewSettings] = useState({});
    const [cpuUsage, setCpuUsage] = useState(0);
    const [ramUsed, setRamUsed] = useState(0);

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`/api/projects/cmc/fetch-overall-metrics`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const data = response.data;

                setModuleStatus({
                    Registration: data.reg ?? false,
                    "Interaction Handler": data.ihmod ?? false,
                });

                setAccountNumber(data.numAccounts || 0);
                setRamUsed(data.used_memory || 0)
                setCpuUsage(data.cpu_percentage || 0)
                setThreadCount(data.ihu || 0);
            })
            .catch(error => {
                console.error('Error fetching metrics:', error);

            });

        axios.get(`/api/projects/cmc/settings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const { proxyUsernameCustom, proxyPasswordCustom, ...filteredSettings } = response.data;
                setNewSettings(filteredSettings);
                setProxyPasswordLocal(proxyPasswordCustom);
                setProxyUsernameLocal(proxyUsernameCustom);
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
    }, []);

    useEffect(() => {
        console.log("ModuleStatus keys after update:", Object.keys(moduleStatus));
    }, [moduleStatus]);

    const handleSettingsSave = () => {
        const token = localStorage.getItem("token");
        const { proxyUsernameCustom, proxyPasswordCustom, ...filteredSettings } = newSettings;
        axios.put(`/api/projects/cmc/settings`, filteredSettings, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const { proxyUsernameCustom, proxyPasswordCustom, ...filteredSettings } = response.data;
                setNewSettings(filteredSettings);
                setProxyPasswordLocal(proxyPasswordCustom);
                setProxyUsernameLocal(proxyUsernameCustom);
                setModalOpen(false);
            })
            .catch(error => {
                console.error('Error updating settings:', error);
            });
    };

    const toggleModuleStatus = (module) => {
        const token = localStorage.getItem("token");
        const newStatus = !moduleStatus[module];

        if(newStatus) {
            if(!serverCreated) {
                toast.error('A server is needed to start a module')
                return;
            }

            if(serverStatus !== 'READY') {
                toast.error('Your server must be in the `Ready` status to be used')
                return;
            }

            if(!proxyPasswordLocal || !proxyUsernameLocal) {
                toast.error('Proxy settings are needed to start a module');
                return;
            }

            //TODO: Verify other necessary settings

            if(module === 'Registration') {

            } else if (module === 'Interaction Handler') {

            }
        }

        axios.put(`/api/projects/cmc/module/${module}`, newStatus, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data) {
                    setModuleStatus(prev => ({
                        ...prev,
                        [module]: newStatus
                    }));
                } else {
                    console.error('Failed to update module status');
                    const errorToastId = "failedToUpdate";

                    toast.error("Error: Failed to update module status", {
                        position: "top-right",
                        autoClose: 5000,
                        toastId: errorToastId,
                    });
                }
            })
            .catch(error => {
                console.error('Error updating module status:', error);
                toast.error('Error starting module; check proxy settings?')
            });
    };

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: "#D1D5DB",
                    font: {
                        size: 12,
                    },
                    boxWidth: 12,
                    boxHeight: 12,
                },
            },
            tooltip: {
                backgroundColor: "#1F2937",
                titleColor: "#F9FAFB",
                bodyColor: "#F9FAFB",
                cornerRadius: 4,
            },
        },
        scales: {
            x: {
                ticks: {color: "#9CA3AF"},
                grid: {color: "rgba(156, 163, 175, 0.2)"},
            },
            y: {
                ticks: {color: "#9CA3AF"},
                grid: {color: "rgba(156, 163, 175, 0.2)"},
            },
        },
    };

    /**
     * Generate the last 7 days (including today) as day labels.
     * Returns an array of day strings and a map of date strings for quick lookup.
     */
    function getLast7Days() {
        const days = [];
        for (let i = 6; i >= 0; i--) {
            const d = dayjs().subtract(i, 'day');
            days.push(d);
        }
        return days;
    }

    /**
     * Transform view data from the backend into chart-ready data.
     */
    function transformViewData(data) {
        // data format: [{metricDate, totalValueGhost, totalValueReal}, ...]
        // We assume metricDate is in YYYY-MM-DD format.
        const last7Days = getLast7Days();
        const labels = last7Days.map(d => d.format('MMM D'));
        const dateMap = {};
        data.forEach(entry => {
            dateMap[entry.metricDate] = {
                ghost: entry.totalValueGhost,
                real: entry.totalValueReal
            };
        });

        const ghostValues = [];
        const realValues = [];
        last7Days.forEach(d => {
            const dateStr = d.format('YYYY-MM-DD');
            if (dateMap[dateStr]) {
                ghostValues.push(dateMap[dateStr].ghost);
                realValues.push(dateMap[dateStr].real);
            } else {
                ghostValues.push(0);
                realValues.push(0);
            }
        });

        return { labels, ghostValues, realValues };
    }

    /**
     * Transform trending data from the backend into chart-ready data.
     */
    function transformTrendData(data) {
        // data format: [{metricDate, values:[]}, ...]
        // For simplicity, let's assume we sum up the values array, or pick an appropriate stat.
        const last7Days = getLast7Days();
        const labels = last7Days.map(d => d.format('MMM D'));
        const dateMap = {};
        data.forEach(entry => {
            const sum = entry.values && entry.values.length > 0
                ? entry.values.reduce((acc, val) => acc + val, 0)
                : 0;
            dateMap[entry.metricDate] = sum;
        });

        const trendValues = [];
        last7Days.forEach(d => {
            const dateStr = d.format('YYYY-MM-DD');
            trendValues.push(dateMap[dateStr] || 51);
        });

        return { labels, trendValues };
    }

    const [accountData, setAccountData] = useState({
        labels: generateLabels(),
        datasets: [
            {
                label: "Ghost Interactions",
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: "#3B82F6",
                backgroundColor: "rgba(59, 130, 246, 0.2)",
                fill: true,
                tension: 0.4,
            },
            {
                label: "Real Interactions",
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: "#6366F1",
                backgroundColor: "rgba(99, 102, 241, 0.2)",
                fill: true,
                tension: 0.4,
            },
        ],
    });
    const [trendingPositionData, setTrendingPositionData] = useState({
        labels: generateLabels(),
        datasets: [
            {
                label: "Trending Position",
                data: [51, 51, 51, 51, 51, 51, 51],
                backgroundColor: "#2563EB",
                borderColor: "#1D4ED8",
                borderWidth: 1,
                borderRadius: 5,
            },
        ],
    });

    let token = localStorage.getItem('token');

    useEffect(() => {
        // Fetch views (ghost/account interactions)
        axios.get('/api/projects/cmc/views', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                const data = res.data; // data is a list of DailyViewMetricDto: [{metricDate, totalValueGhost, totalValueReal}, ...]
                const { labels, ghostValues, realValues } = transformViewData(data);
                setAccountData({
                    labels,
                    datasets: [
                        {
                            label: 'Ghost Interactions',
                            data: ghostValues,
                            borderColor: "#3B82F6",
                            backgroundColor: "rgba(59, 130, 246, 0.2)",
                            fill: true,
                            tension: 0.4,
                        },
                        {
                            label: 'Real Interactions',
                            data: realValues,
                            borderColor: "#6366F1",
                            backgroundColor: "rgba(99, 102, 241, 0.2)",
                            fill: true,
                            tension: 0.4,
                        },
                    ],
                });
            })
            .catch(err => console.error("Error fetching views:", err));

        // Fetch trends (trending position)
        axios.get('/api/projects/cmc/trends', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                const data = res.data; // data is a list of DailyTrendMetricDto: [{metricDate, values:[]}, ...]
                const { labels, trendValues } = transformTrendData(data);
                setTrendingPositionData({
                    labels,
                    datasets: [
                        {
                            label: 'Trending Position',
                            data: trendValues,
                            backgroundColor: "#2563EB",
                            borderColor: "#1D4ED8",
                            borderWidth: 1,
                            borderRadius: 5,
                        }
                    ]
                });
            })
            .catch(err => console.error("Error fetching trends:", err));

    }, [token]);

    const [scheduleStartDate, setScheduleStartTime] = useState([]);
    const [scheduleEndDate, setScheduleEndTime] = useState([]);
    const [hitsDataReal, setHitsDataReal] = useState([]);
    const [hitsDataGhost, setHitsDataGhost] = useState([]);
    const currentTime = new Date(); // Current time for reference
    const [hoursSinceStart, setHoursSinceStart] = useState([]);

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                // Make a GET request to /schedule
                const token = localStorage.getItem('token');
                const response = await axios.get("/api/projects/cmc/schedule", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { startTime, endTime, scheduledCountsGhost, scheduledCountsReal } = response.data;

                // Parse startTime and endTime into Date objects
                if (startTime && endTime) {
                    setScheduleStartTime(new Date(startTime));
                    setScheduleEndTime(new Date(endTime));
                    setHoursSinceStart(Math.floor((currentTime - scheduleStartDate) / (1000 * 60 * 60)));
                    setHitsDataReal(scheduledCountsReal || []);
                    setHitsDataGhost(scheduledCountsGhost || []);
                } else {
                    setHoursSinceStart(0);
                    setScheduleStartTime(new Date(0));
                    setScheduleEndTime(new Date(1000 * 60 * 60 * 24));
                    setHitsDataReal([]);
                    setHitsDataGhost([]);
                    console.error("Invalid schedule data:", response.data);
                }
            } catch (error) {
                setHoursSinceStart(0);
                setScheduleStartTime(new Date(0));
                setScheduleEndTime(new Date(1000 * 60 * 60 * 24));
                setHitsDataReal([]);
                setHitsDataGhost([]);
                console.error("Error fetching schedule:", error);
            }
        };

        fetchSchedule();
    }, []); // Empty dependency array means it runs only once on mount


    function formatDate(date) {
        return date.toLocaleString(undefined, {dateStyle: 'medium', timeStyle: 'short'});
    }

    function onResetSchedule() {
        let token = localStorage.getItem('token');
        axios.put('/api/projects/cmc/schedule', null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(() => toast.success('Successfully reset schedule'))
            .catch(() => toast.error("Failed to reset schedule"));
    }

    const [consoleLogs, setConsoleLogs] = useState([]);

    useEffect(() => {
        if (showLogsModal) {
            // Fetch logs when the modal is shown
            const fetchLogs = async () => {
                try {
                    const token = localStorage.getItem('token'); // Assuming token is stored here
                    const response = await axios.get('/api/projects/cmc/console', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    // Transform the ConsoleEntry objects into a frontend-friendly format
                    const formattedLogs = response.data.map(entry => ({
                        time: new Date(entry.time).toLocaleString(), // Convert LocalDateTime to a readable string
                        line: entry.line,
                    }));

                    setConsoleLogs(formattedLogs);
                } catch (error) {
                    console.error("Failed to fetch console logs:", error);
                }
            };

            fetchLogs();
        }
    }, [showLogsModal]);

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
            <ToastContainer/> {/* Add this here */}
            <div className="p-6 space-y-6">
                {/* Navigation */}
                <nav className="flex items-center justify-between bg-gray-800 bg-opacity-50 p-4 rounded-xl shadow">
                    <button
                        className="flex items-center text-gray-300 hover:text-white"
                        onClick={() => navigate("/panel")}
                    >
                        <FiArrowLeft className="mr-2" size={20}/>
                        Back to Project List
                    </button>
                    <div className="flex items-center space-x-4">
                        <button
                            className="inline-flex items-center px-4 py-2 bg-gray-700 text-gray-300 rounded-lg shadow hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            onClick={() => setModalOpen(true)}
                        >
                            <FiSettings className="mr-2"/>
                            Modify Settings
                        </button>

                        <button
                            className="inline-flex items-center px-4 py-2 bg-gray-700 text-gray-300 rounded-lg shadow hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            onClick={() => setMetricsModalOpen(true)}
                        >
                            <FiBarChart2 className="mr-2" size={20}/> {/* Creative icon for metrics */}
                            View Other Metrics
                        </button>
                    </div>
                </nav>

                {/* Header */}
                <header className="bg-gray-800 bg-opacity-50 shadow-sm rounded-xl p-6">
                    <div>
                        <h1 className="text-4xl font-bold text-blue-400">
                            Project Dashboard
                        </h1>
                        <p className="text-gray-400 mt-2">
                            CoinMarketCap: Trending Suite
                        </p>
                    </div>
                </header>

                {/* Charts Section */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Ghost/Account Interactions */}
                    <motion.div
                        className="lg:col-span-2 bg-gray-800 bg-opacity-50 shadow-sm rounded-xl p-6 flex flex-col h-96"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6}}
                    >
                        <h2 className="text-xl font-semibold mb-4 text-blue-400">
                            Ghost/Account Interactions
                        </h2>
                        <div className="flex-1">
                            {accountData ? (
                                <Line data={accountData} options={chartOptions}/>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    </motion.div>

                    {/* Trending Position */}
                    <motion.div
                        className="bg-gray-800 bg-opacity-50 shadow-sm rounded-xl p-6 flex flex-col h-96"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6, delay: 0.2}}
                    >
                        <h2 className="text-xl font-semibold mb-4 text-blue-400">
                            Trending Position
                        </h2>
                        <div className="flex-1">
                            {trendingPositionData ? (
                                <Bar data={trendingPositionData} options={chartOptions}/>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    </motion.div>
                </div>

                {/* Speedometer and Module Control */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Speedometer */}
                    <div className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-sm flex flex-col items-center">
                        <h2 className="text-xl font-semibold mb-4 text-blue-400">
                            Current Thread Count
                        </h2>
                        <MemoizedGaugeChart threadCount={threadCount}/>
                        <p className="mt-4 text-gray-400">
                            {threadCount} / 1000 threads used
                        </p>


                    </div>

                    {/* Module Control */}
                    <motion.div
                        className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-sm"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6}}
                    >
                        <h2 className="text-xl font-semibold mb-6 text-blue-400">
                            Module Control
                        </h2>
                        {Object.keys(moduleStatus).length > 0 ? (
                            Object.keys(moduleStatus).map((module) => (
                                <div
                                    key={module}
                                    className="flex justify-between items-center mb-6"
                                >
                                    <span className="text-gray-300 capitalize">
                                        {module.replace(/([A-Z])/g, " $1")}
                                    </span>
                                    <Switch
                                        checked={moduleStatus[module]}
                                        onChange={() => toggleModuleStatus(module)}
                                        className={`${
                                            moduleStatus[module]
                                                ? "bg-blue-600"
                                                : "bg-gray-600"
                                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
                                    >
                                        <span
                                            className={`${
                                                moduleStatus[module]
                                                    ? "translate-x-6"
                                                    : "translate-x-1"
                                            } inline-block w-4 h-4 transform bg-gray-800 rounded-full transition-transform`}
                                        />
                                    </Switch>
                                </div>
                            ))
                        ) : (
                            <p>Loading module statuses...</p>
                        )}
                    </motion.div>
                </div>

                {/* Server Management Section */}
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-xl shadow space-y-8 max-w-3xl mx-auto">

                    <h2 className="text-2xl font-bold text-blue-400">Server Management</h2>

                    {!serverCreated ? (
                        <>
                            {/* Instance Selection (Shown only if server not created) */}
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-300">
                                    Select Server Instance
                                </label>
                                <select
                                    value={selectedInstance}
                                    onChange={handleInstanceChange}
                                    className="block w-full p-3 bg-white border border-gray-300 rounded-md shadow-sm
                     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                     appearance-none cursor-pointer text-gray-700"
                                >
                                    <option disabled value="">
                                        Select a Server Instance
                                    </option>
                                    {serverInstances.map((instance) => (
                                        <option key={instance.id} value={instance.id}>
                                            {instance.name} - {instance.ram} RAM, {instance.vcpus} vCPUs, {instance.bandwidth},
                                            ${instance.cost} / hr
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex items-center space-x-4 pt-4">
                                <button
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md
                     hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onClick={handleCreateServer}
                                >
                                    Create Server
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="space-y-8">

                            {/* Selected Server Details */}
                            <div className="bg-gray-700 p-6 rounded-lg shadow-inner space-y-4">
                                <h3 className="text-lg font-semibold text-gray-200">Selected Server Details</h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 text-gray-300">
                                    <div className="flex flex-col space-y-1">
                                        <span className="font-medium text-gray-100">Name</span>
                                        <span>{serverInstances.find((instance) => instance.id === selectedInstance)?.name}</span>
                                    </div>
                                    <div className="flex flex-col space-y-1">
                                        <span className="font-medium text-gray-100">Cost</span>
                                        <span>${serverInstances.find((instance) => instance.id === selectedInstance)?.cost} / hr</span>
                                    </div>
                                    <div className="flex flex-col space-y-1">
                                        <span className="font-medium text-gray-100">RAM</span>
                                        <span>{serverInstances.find((instance) => instance.id === selectedInstance)?.ram}</span>
                                    </div>
                                    <div className="flex flex-col space-y-1">
                                        <span className="font-medium text-gray-100">CPU Cores</span>
                                        <span>{serverInstances.find((instance) => instance.id === selectedInstance)?.vcpus}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Server Status */}
                            <div className="flex items-center space-x-3 text-gray-300">
                                <span className="font-medium">Status:</span>
                                <div className="flex items-center space-x-2">
                                    <div
                                        className={`w-3 h-3 rounded-full ${
                                            serverStatus === 'READY' ? 'bg-green-500' : 'bg-red-500'
                                        }`}
                                    ></div>
                                    <span className="text-gray-200">
            {serverStatus === 'READY' ? 'Ready' : (serverStatus === 'ACTIVE' ? 'Processing deployment' : (serverStatus === 'ERROR' ? 'Error setting up server' : (serverStatus === 'DELETING' ? 'Deleting' : 'Starting up')))}
          </span>
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-wrap items-center space-x-4">
                                <button
                                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500
                     transition focus:outline-none focus:ring-2 focus:ring-green-400"
                                    onClick={() => setShowLogsModal(true)}
                                >
                                    View Logs
                                </button>
                                <button
                                    className="px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-500
                     transition focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                    onClick={handleRestartServer}
                                >
                                    Restart Server
                                </button>
                                <button
                                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-500
                     transition focus:outline-none focus:ring-2 focus:ring-red-400"
                                    onClick={handleDeleteServer}
                                >
                                    Delete Server
                                </button>
                            </div>

                            {/* Server Metrics */}
                            <div className="bg-gray-900 p-6 rounded-lg shadow-inner space-y-2">
                                <h3 className="text-lg font-semibold text-gray-200">Server Metrics</h3>
                                <div className="text-gray-300">
                                    <p>CPU Usage: <span className="font-bold text-gray-100">{cpuUsage}%</span></p>
                                    <p>RAM Usage: <span className="font-bold text-gray-100">{ramUsed} GB / {serverInstances.find((instance) => instance.id === selectedInstance)?.ram}</span></p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Logs Modal */}
            <AnimatePresence>
                {showLogsModal && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-70"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-2xl relative"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                        >
                            <button
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                                onClick={() => setShowLogsModal(false)}
                            >
                                <FiX size={24} />
                            </button>
                            <h3 className="text-2xl font-bold text-white mb-4">
                                Last 100 Lines of Console Logs
                            </h3>
                            <div className="bg-black text-green-400 p-4 rounded-lg overflow-y-auto h-96 space-y-1">
                                {consoleLogs.length > 0 ? (
                                    consoleLogs.map((log, index) => (
                                        <p key={index}>
                                            [{log.time}] {log.line}
                                        </p>
                                    ))
                                ) : (
                                    <p className="text-gray-400">No logs available.</p>
                                )}
                            </div>
                            <div className="mt-4 flex justify-end">
                                <button
                                    className="px-4 py-2 bg-blue-600 text-gray-200 rounded-lg hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onClick={() => setShowLogsModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Modal */}
            <AnimatePresence>
                {metricsModalOpen && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <motion.div
                            className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-lg relative overflow-hidden"
                            initial={{scale: 0.8}}
                            animate={{scale: 1}}
                            exit={{scale: 0.8}}
                            transition={{duration: 0.3}}
                        >
                            <button
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-200 focus:outline-none"
                                onClick={() => setMetricsModalOpen(false)}
                            >
                                <FiX size={24}/>
                            </button>
                            <h3 className="text-2xl font-bold text-white mb-6">Other Metrics</h3>

                            <div className="space-y-4">
                                {/* Accounts Created */}
                                <div className="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                                    <span className="text-gray-300">Accounts Created</span>
                                    <span className="text-gray-300 font-bold text-lg">{accountNumber}</span>
                                </div>

                                {/* Schedule Start Date */}
                                <div className="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                                    <span className="text-gray-300">Schedule Start Date</span>
                                    <span className="text-gray-300 font-bold text-sm md:text-base">
                                    {formatDate(scheduleStartDate)}
                                </span>
                                </div>

                                {/* Schedule End Date */}
                                <div className="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                                    <span className="text-gray-300">Schedule End Date</span>
                                    <span className="text-gray-300 font-bold text-sm md:text-base">
                                    {formatDate(scheduleEndDate)}
                                </span>
                                </div>

                                {/* Hits per Hour Graph */}
                                <div className="bg-gray-700 p-4 rounded-lg">
                                    <h4 className="text-white font-semibold mb-2 text-sm md:text-base">Ghost Account View
                                        Schedule (24h)</h4>
                                    <div className="relative w-full h-24 bg-gray-600 rounded-md overflow-hidden">
                                        {/* Hour segments (just visual guides) */}
                                        <div className="absolute inset-0 flex">
                                            {hitsDataGhost.map((hits, i) => (
                                                <div
                                                    key={i}
                                                    className="flex-1 flex flex-col justify-end"
                                                    style={{borderLeft: i === 0 ? 'none' : '1px solid rgba(255,255,255,0.1)'}}
                                                >
                                                    <div
                                                        className="mx-0.5 bg-green-400 transition-all"
                                                        style={{
                                                            height: `${(hits / Math.max(...hitsDataGhost)) * 100}%`,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* Current time line (red line) */}
                                        <div
                                            className="absolute top-0 bottom-0 w-0.5 bg-red-500"
                                            style={{left: `${(hoursSinceStart / 24) * 100}%`}}
                                        />
                                    </div>
                                    <div className="flex justify-between mt-2 text-gray-400 text-xs">
                                        <span>{formatDate(scheduleStartDate)}</span>
                                        <span>{formatDate(scheduleEndDate)}</span>
                                    </div>
                                </div>

                                {/* Hits per Hour Graph */}
                                <div className="bg-gray-700 p-4 rounded-lg">
                                    <h4 className="text-white font-semibold mb-2 text-sm md:text-base">Real Account View
                                        Schedule (24h)</h4>
                                    <div className="relative w-full h-24 bg-gray-600 rounded-md overflow-hidden">
                                        {/* Hour segments (just visual guides) */}
                                        <div className="absolute inset-0 flex">
                                            {hitsDataReal.map((hits, i) => (
                                                <div
                                                    key={i}
                                                    className="flex-1 flex flex-col justify-end"
                                                    style={{borderLeft: i === 0 ? 'none' : '1px solid rgba(255,255,255,0.1)'}}
                                                >
                                                    <div
                                                        className="mx-0.5 bg-green-400 transition-all"
                                                        style={{
                                                            height: `${(hits / Math.max(...hitsDataReal)) * 100}%`,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* Current time line (red line) */}
                                        <div
                                            className="absolute top-0 bottom-0 w-0.5 bg-red-500"
                                            style={{left: `${(hoursSinceStart / 24) * 100}%`}}
                                        />
                                    </div>
                                    <div className="flex justify-between mt-2 text-gray-400 text-xs">
                                        <span>{formatDate(scheduleStartDate)}</span>
                                        <span>{formatDate(scheduleEndDate)}</span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-6 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
                                <button
                                    className="px-4 py-2 bg-red-600 text-gray-100 rounded-lg hover:bg-red-500 transition focus:outline-none focus:ring-2 focus:ring-red-400"
                                    onClick={onResetSchedule}
                                >
                                    Reset Schedule
                                </button>
                                <button
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    onClick={() => setMetricsModalOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {isModalOpen && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <motion.div
                            className="bg-gray-800 rounded-xl shadow-lg p-6 w-full max-w-lg relative"
                            initial={{scale: 0.8}}
                            animate={{scale: 1}}
                            exit={{scale: 0.8}}
                            transition={{duration: 0.3}}
                        >
                            <button
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                                onClick={() => setModalOpen(false)}
                            >
                                <FiX size={24}/>
                            </button>
                            <h3 className="text-2xl font-bold text-white mb-6">Modify Settings</h3>

                            {/* Scrollable container */}
                            <div
                                className="space-y-6 overflow-y-auto max-h-96 pr-4 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
                                {/* Proxy Settings Section */}
                                <div className="bg-gray-700 rounded-lg p-5 shadow-md border border-gray-600">
                                    <h4 className="text-xl font-semibold text-white mb-4">Smartproxy
                                        Credentials</h4>
                                    <p className="text-sm text-gray-300 mb-4">
                                        Configure your proxy server credentials below. Use the "Check & Save Proxy"
                                        button to verify connectivity and save (separately from save changes). We
                                        recommend restarting running tasks if you hit a data cap during them.
                                    </p>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-300 mb-2">
                                                Proxy Username
                                            </label>
                                            <input
                                                type="text"
                                                value={proxyUsernameLocal}
                                                onChange={(e) => setProxyUsernameLocal(e.target.value)}
                                                className="w-full px-4 py-2 bg-gray-700 text-gray-100 border border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-300 mb-2">
                                                Proxy Password
                                            </label>
                                            <input
                                                type="password"
                                                value={proxyPasswordLocal}
                                                onChange={(e) => setProxyPasswordLocal(e.target.value)}
                                                className="w-full px-4 py-2 bg-gray-700 text-gray-100 border border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>

                                        {/* Check & Save Proxy Button */}
                                        <div className="flex justify-end">
                                            <button
                                                onClick={handleProxyCheckAndSave}
                                                disabled={isTestingProxy}
                                                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            >
                                                {isTestingProxy ? 'Testing...' : 'Check & Save Proxy'}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Existing dynamic settings (excluding proxy keys to prevent duplication) */}
                                {Object.keys(newSettings)
                                    .filter((key) => !['proxyUsernameCustom', 'proxyPasswordCustom'].includes(key))
                                    .map((key) => (
                                        <div key={key}>
                                            <label className="block text-sm font-medium text-gray-300 mb-2">
                                                {settingDescriptions[key] || key.replace(/_/g, ' ')}
                                            </label>
                                            <input
                                                type="text"
                                                value={newSettings[key]}
                                                onChange={(e) =>
                                                    setNewSettings((prev) => ({
                                                        ...prev,
                                                        [key]: e.target.value,
                                                    }))
                                                }
                                                className="w-full px-4 py-2 bg-gray-700 text-gray-100 border border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>
                                    ))}
                            </div>

                            <div className="mt-8 flex justify-end space-x-4">
                                <button
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    onClick={() => setModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onClick={handleSettingsSave}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ProjectCMC;