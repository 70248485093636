// src/HomePage.js

import React, {useEffect, useState} from "react";
import Lottie from "react-lottie-player";
import wavingHandAnimation from "../assets/wavingHand.json";
import rocketAnimation from "../assets/rocket.json";
import { motion, AnimatePresence } from "framer-motion";
import {jwtDecode} from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage = () => {
    const [showLearnMore, setShowLearnMore] = useState(false);
    const [isLoginOpen, setLoginOpen] = useState(false);
    const [isPasswordResetOpen, setPasswordResetOpen] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isResetting, setIsResetting] = useState(false); // Loading state

    const navigate = useNavigate();

    const isTokenValid = () => {
        const token = localStorage.getItem("token");
        if (!token) return false;

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp && decodedToken.exp < currentTime) {
                return false;
            }

            return true;
        } catch (error) {
            console.error("Token validation error:", error);
            return false;
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/api/auth/login", {
                username,
                password,
            });

            const { token, tempPassword } = response.data;
            localStorage.setItem("token", token);
            localStorage.setItem("tempPassword", tempPassword);

            if (tempPassword) {
                setLoginOpen(false);
                setPasswordResetOpen(true);
            } else {
                setLoginOpen(false);
                if (username === "superuser") {
                    navigate("/admin");
                } else {
                    navigate("/panel");
                }
            }
        } catch (error) {
            console.error("Login Error:", error);

            if (error.response) {
                if (error.response.status === 401) {
                    toast.error("Invalid username or password.");
                } else if (error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("An error occurred. Please try again.");
                }
            } else {
                toast.error("Unable to connect to the server. Please try again later.");
            }
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match. Please try again.");
            return;
        }

        try {
            setIsResetting(true);

            const token = localStorage.getItem("token");
            if (!token) {
                toast.error("Unauthorized. Please log in again.");
                setPasswordResetOpen(false);
                navigate("/login");
                return;
            }

            const response = await axios.post(
                "/api/auth/reset-password",
                {
                    newPassword,
                    confirmPassword,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setPasswordResetOpen(false);
                setNewPassword("");
                setConfirmPassword("");
                localStorage.removeItem("tempPassword");
                toast.success("Your password has been reset successfully!");
                navigate("/panel");
            }
        } catch (error) {
            console.error("Password Reset Error:", error);
            if (error.response && error.response.data) {
                toast.error(
                    error.response.data.message || "Password reset failed. Please try again."
                );
            } else {
                toast.error("Unable to connect to the server. Please try again later.");
            }
        } finally {
            setIsResetting(false);
        }
    };

    // Images you want to preload
    const imagesToLoad = [
        "/images/automation_home.png",
        "/images/astro_login_picture.webp",
    ];

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let loadedImages = 0;
        imagesToLoad.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImages++;
                if (loadedImages === imagesToLoad.length) {
                    // All images loaded
                    setIsLoading(false);
                }
            };
            img.onerror = () => {
                // Even if image fails, consider it "done" to avoid infinite loading.
                loadedImages++;
                if (loadedImages === imagesToLoad.length) {
                    setIsLoading(false);
                }
            };
        });
    }, []);

    if (isLoading) {
        // Loading screen with a spinner/rocket-themed animation
        return (
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-gray-100">
                <div className="flex flex-col items-center space-y-4">
                    <Lottie
                        loop
                        animationData={rocketAnimation}
                        play
                        style={{ width: 150, height: 150 }}
                    />
                    <p className="text-gray-700 text-xl font-medium">Loading, please wait...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="font-sans relative">
            <ToastContainer /> {/* Add the ToastContainer */}
            <AnimatePresence>
                {!showLearnMore && (
                    <motion.div
                        key="home"
                        className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-100 flex flex-col justify-center items-center text-center px-4 md:px-6"
                        initial={{ x: 0 }}
                        animate={{ x: 0 }}
                        exit={{ x: "-100%", opacity: 0 }}
                        transition={{ duration: 0.6, ease: "easeInOut" }}
                    >
                        {/* Hero Section */}
                        <motion.h1
                            className="text-4xl sm:text-5xl md:text-7xl font-bold text-gray-900 leading-tight"
                            initial="hidden"
                            animate="visible"
                            variants={{
                                hidden: { opacity: 0 },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        staggerChildren: 0.1,
                                    },
                                },
                            }}
                        >
                            {["Elevate", "Your", "Automation"].map((word, index) => (
                                <motion.span
                                    key={word}
                                    className={`inline-block ${
                                        index === 2
                                            ? "text-blue-600 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-600"
                                            : ""
                                    }`}
                                    variants={{
                                        hidden: { opacity: 0, y: 20 },
                                        visible: {
                                            opacity: 1,
                                            y: 0,
                                            transition: { duration: 0.7 },
                                        },
                                    }}
                                >
                                    {word}
                                    {index < 2 && <span className="mx-1 sm:mx-2"></span>}{" "}
                                    {/* Space between words */}
                                </motion.span>
                            ))}
                        </motion.h1>
                        <p className="mt-4 sm:mt-6 text-base sm:text-lg md:text-xl text-gray-600 max-w-2xl">
                            Discover secure, cutting-edge solutions built to streamline your
                            workflow and empower innovation.
                        </p>
                        <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={() => setShowLearnMore(true)}
                                className="w-full sm:w-auto px-6 sm:px-8 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-500 transition"
                            >
                                Learn More
                            </button>
                            <button
                                onClick={() => {
                                    if (isTokenValid()) {
                                        const tempPassword = localStorage.getItem("tempPassword");
                                        if (tempPassword === "true") {
                                            setPasswordResetOpen(true);
                                        } else {
                                            navigate("/panel");
                                        }
                                    } else {
                                        setLoginOpen(true);
                                    }
                                }}
                                className="w-full sm:w-auto px-6 sm:px-8 py-3 bg-gray-200 text-gray-700 rounded-lg shadow-lg hover:bg-gray-300 transition"
                            >
                                Login
                            </button>
                        </div>
                    </motion.div>
                )}

                {showLearnMore && (
                    <motion.div
                        key="learn-more"
                        className="fixed inset-0 bg-white overflow-auto"
                        initial={{ opacity: 0, x: "100%" }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: "100%" }}
                        transition={{ duration: 0.6, ease: "easeInOut" }}
                    >
                        {/* Container */}
                        <div className="flex flex-col min-h-screen px-4 sm:px-6 md:px-12">
                            {/* Back Button */}
                            <div className="pt-4 sm:pt-6">
                                <button
                                    onClick={() => setShowLearnMore(false)}
                                    className="text-gray-600 hover:text-blue-600 text-lg font-medium transition"
                                >
                                    ← Back
                                </button>
                            </div>

                            {/* Main Content */}
                            <div className="flex-grow flex flex-col justify-center">
                                {/* Header Section */}
                                <header className="text-center space-y-4 sm:space-y-6">
                                    <h1 className="text-3xl sm:text-4xl md:text-6xl font-extrabold text-gray-900">
                                        Revolutionizing Automation and Marketing
                                    </h1>
                                    <p className="text-base sm:text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
                                        At Skyrocketer, we integrate robotics and modern web solutions
                                        to optimize workflows, enhance automation, and drive marketing
                                        innovation.
                                    </p>
                                </header>

                                {/* Decorative and Informative Section */}
                                <div className="mt-8 sm:mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 items-center w-full max-w-6xl mx-auto">
                                    {/* Left Visual */}
                                    <div className="relative order-2 md:order-1">
                                        <div className="absolute -top-4 -left-4 w-16 h-16 sm:w-32 sm:h-32 bg-blue-100 rounded-full"></div>
                                        <div className="absolute top-8 right-4 w-8 h-8 sm:w-16 sm:h-16 bg-indigo-100 rounded-full"></div>
                                        <img
                                            src="/images/automation_home.png"
                                            alt="Automation Home"
                                            className="w-full h-auto rounded-lg shadow-lg"
                                        />
                                    </div>

                                    {/* Right Informative Content */}
                                    <div className="space-y-4 sm:space-y-6 order-1 md:order-2">
                                        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-800">
                                            How We Work
                                        </h2>
                                        <p className="text-base sm:text-lg text-gray-600">
                                            By combining advanced robotics systems with seamless website
                                            integration, we empower businesses to automate repetitive
                                            tasks and enhance their marketing outreach. Our solutions are
                                            tailored to streamline operations and deliver measurable
                                            results.
                                        </p>
                                        <ul className="space-y-3 sm:space-y-4 text-gray-600">
                                            <li className="flex items-start">
                                                <span className="w-2 h-2 sm:w-3 sm:h-3 bg-blue-600 rounded-full mr-2 sm:mr-3 mt-1 sm:mt-2"></span>
                                                Robotics solutions for workflow automation.
                                            </li>
                                            <li className="flex items-start">
                                                <span className="w-2 h-2 sm:w-3 sm:h-3 bg-blue-600 rounded-full mr-2 sm:mr-3 mt-1 sm:mt-2"></span>
                                                AI-powered systems for data-driven marketing strategies.
                                            </li>
                                            <li className="flex items-start">
                                                <span className="w-2 h-2 sm:w-3 sm:h-3 bg-blue-600 rounded-full mr-2 sm:mr-3 mt-1 sm:mt-2"></span>
                                                Seamless integration of websites and automation tools.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Login Modal */}
            {isLoginOpen && (
                <motion.div
                    className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 px-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="bg-white rounded-3xl shadow-2xl flex flex-col md:flex-row w-full max-w-5xl overflow-hidden">
                        {/* Login Form Section */}
                        <div className="p-6 sm:p-10 flex flex-col justify-center relative">
                            <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4 sm:mb-6">
                                Skyrocketer
                            </h3>

                            <form className="space-y-4 sm:space-y-6" onSubmit={handleLogin}>
                                <input
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                                <button
                                    type="submit"
                                    className="w-full bg-blue-600 text-white py-2 rounded-lg shadow-md hover:bg-blue-500 transition"
                                >
                                    Login
                                </button>
                            </form>
                            <button
                                className="mt-4 text-sm text-blue-600 hover:underline"
                                onClick={() => {
                                    setLoginOpen(false);
                                }}
                            >
                                Close
                            </button>
                        </div>

                        {/* Information Panel */}
                        <div className="hidden md:flex bg-blue-600 text-white p-6 sm:p-10 flex-col justify-center relative">
                            {/* Text Content */}
                            <div className="z-20 space-y-4 text-center md:text-left">
                                <h3 className="text-2xl sm:text-3xl md:text-4xl font-extrabold">
                                    Secure, Intelligent, Automated Solutions
                                </h3>
                                <p className="text-gray-200 text-base sm:text-lg max-w-md mx-auto md:mx-0">
                                    Skyrocketer specializes in building tools designed to
                                    streamline workflows and power innovation for businesses.
                                </p>
                            </div>

                            {/* Rocket Animation */}
                            <div className="relative z-10 flex justify-center">
                                <Lottie
                                    loop
                                    animationData={rocketAnimation}
                                    play
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        marginTop: "20px",
                                    }}
                                />
                            </div>

                            {/* Fullscreen Illustration */}
                            <div className="absolute inset-0 top-0 h-full w-full opacity-25">
                                <img
                                    src="/images/astro_login_picture.webp"
                                    alt="Background Illustration"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}

            {/* Password Reset Modal */}
            <AnimatePresence>
                {isPasswordResetOpen && (
                    <motion.div
                        className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 px-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <div className="bg-white rounded-lg shadow-2xl w-full max-w-md p-6">
                            <h3 className="text-xl font-semibold mb-4">Reset Your Password</h3>
                            <form className="space-y-4" onSubmit={handlePasswordReset}>
                                <div>
                                    <label className="block text-gray-700">New Password</label>
                                    <input
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Confirm New Password</label>
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition ${
                                            isResetting ? "opacity-50 cursor-not-allowed" : ""
                                        }`}
                                        disabled={isResetting}
                                    >
                                        {isResetting ? "Resetting..." : "Reset Password"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Contact Us Floating Element */}
            <div className="fixed bottom-6 right-6 z-50 flex items-center">
                {/* Animation of someone waving */}
                <div
                    onClick={() => navigate("/contact-us")}
                    className="cursor-pointer flex items-center space-x-2 bg-white shadow-lg rounded-full p-2 pr-4 hover:bg-blue-100 transition"
                >
                    <div className="w-12 h-12">
                        <Lottie
                            loop
                            animationData={wavingHandAnimation}
                            play
                            style={{ width: "100%", height: "100%" }}
                        />
                    </div>
                    <span className="text-gray-800 font-medium">
            Interested in a custom solution?
          </span>
                </div>
            </div>
        </div>
    );
};

export default HomePage;